import React, { useState } from "react"

const defaultState = {
  splashPlayed: false,
  setSplashPlayed: () => {},
  loading: false,
  setLoading: () => {},
  data: {},
  setData: () => {},
}

const GlobalContext = React.createContext(defaultState)

const GlobalProvider = ({ children }) => {
  const [splashPlayed, setSplashPlayed] = useState(false)
  const [loading, setLoading] = useState(false)

  // DATA DISABLED

  // const [data, setData] = useState({})

  // const getDataType = dataType => {
  //   // setData({ ...data, ...datasetObj })
  //   if (data[dataType]) {
  //     return data[dataType]
  //   }

  // }

  // const addDataType = datasetObj => {
  //   setData({ ...data, ...datasetObj })
  // }

  return (
    <GlobalContext.Provider
      value={{
        splashPlayed,
        setSplashPlayed,
        loading,
        setLoading,
        // data,
        // addDataType,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalProvider }

export default GlobalContext
