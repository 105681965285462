// import { future } from "@theme-ui/presets"

const heading = {
  color: "text",
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
}

const responsiveFonts = {
  title: [30, 40, 50, 60],
  titleSmall: [30, 35, 35, 40],
  // titleSmall: [30, 35, 40, 50],
  body: [20, 25, 30, 35],
  bodySmall: [15, 20, 25, 30],
  bodyList: [20, 20, 25, 30],
  bodyVerySmall: ["15px", "17px", "19px", "21px"],
  secondaryIndicatorTitle: [18, 18, 16, 16],
  secondaryIndicatorValue: [20, 20, 25, 25],
  secondaryIndicatorVariation: ["12px", "12px", "8px", "10px"],
}

export default {
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    sidebar: 256,
    sidepanel: 500,
  },
  fonts: {
    body: "SharpSans-Medium",
    heading: "SharpSans-Semibold",
    fontSlim: "SharpSans-Medium",
    fontSemi: "SharpSans-Semibold",
    fontBold: "SharpSans-Bold",
    // body: "system-ui, sans-serif",
    // heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    slim: 300,
    medium: 400,
    // heading: 900,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  initialColorMode: "dark",
  initialColorModeName: "dark",
  colors: {
    text: "#fff",
    textSemiTransparent: "rgba(255,255,255,0.4)",
    // backgroundGradient:
    //   "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
    background: "#000",
    backgroundSemiTransparent: "rgba(0,0,0,0.4)",
    primary: "#0048FF",
    secondary: "#ff607b",
    accent: "#ff8bcb",
    muted: "#d3e9ef",
    electric: "#3cdcff",
    modes: {
      light: {
        text: "#000",
        // backgroundGradient:
        //   "linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0))",
        textSemiTransparent: "rgba(0,0,0,0.4)",
        backgroundSemiTransparent: "rgba(255,255,255,0.4)",
        background: "#fff",
        // primary: "#d3e9ef",
        // secondary: "#ff8bcb",
        // primary: "#3cf",
        // secondary: "#e0f",
        // muted: "#111",
      },
    },
  },
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
    },
    secondary: {
      color: "white",
      bg: "secondary",
    },
  },
  badges: {
    outline: {
      color: "text",
      bg: "transparent",
      boxShadow: "inset 0 0 0 1px",
    },
  },
  styles: {
    // Base
    h1: {
      ...heading,
      fontSize: 5,
    },
    h2: {
      ...heading,
      fontSize: 4,
    },
    h3: {
      ...heading,
      fontSize: 3,
    },
    h4: {
      ...heading,
      fontSize: 2,
    },
    h5: {
      ...heading,
      fontSize: 1,
    },
    h6: {
      ...heading,
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },

    // Custom
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      // margin: 0,
    },
    header: {
      backgroundColor: "background",
      // backgroundColor: "primary",
      borderBottom: "4px solid",
      borderBottomColor: "primary",
    },
    headerContent: {
      display: "flex",
      flexWrap: ["wrap", "nowrap", "nowrap", "nowrap"],
      alignItems: "center",
      justifyContent: [null, "flex-end", "flex-end", "flex-end"],
      // maxWidth: "90%",
      width: "90%",
      maxWidth: 1200,
      mx: "auto",
      fontFamily: "fontBold",

      // px: 3,
    },
    homeNavLink: {
      color: "text",
      // color: "white",
      textDecoration: "none",
      // fontSize: responsiveFonts.body,
      fontSize: [24, 32, 32, 32],
      // fontWeight: "300",
      fontWeight: "heading",
      py: 2,
    },
    homeNavLinkText: {
      verticalAlign: "middle",
      ml: 3,
    },
    navLink: {
      color: "text",
      // color: "white",
      textDecoration: "none",
      fontWeight: "heading",
      ml: [0, 4, 4, 4],
      pt: 2,
      pb: 0,
      ":hover": {
        pt: 1,
        pb: 0,
        borderTop: ["none", "4px solid", "4px solid", "4px solid"],
        borderTopColor: ["none", "electric", "electric", "electric"],
        borderBottom: ["4px solid", "none", "none", "none"],
        borderBottomColor: ["electric", "none", "none", "none"],
      },
    },
    navLinkActive: {
      color: "text",
      // color: "white",
      textDecoration: "none",
      fontWeight: "heading",
      ml: [0, 4, 4, 4],
      pt: 1,
      pb: 0,
      borderTop: ["none", "4px solid", "4px solid", "4px solid"],
      borderTopColor: ["none", "secondary", "secondary", "secondary"],
      borderBottom: ["4px solid", "none", "none", "none"],
      borderBottomColor: ["secondary", "none", "none", "none"],
    },
    card: {
      width: "90%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    cardTitle: {
      color: "text",
      // color: "white",
      textDecoration: "none",
      // fontWeight: "slim",
      fontFamily: "fontBold",
      fontSize: responsiveFonts.bodyList,
      py: 2,
    },
    cardDescription: {
      color: "text",
      // color: "white",
      textDecoration: "none",
      fontSize: "small",
      py: 2,
    },
    expandButton: {
      appearance: "none",
      width: 32,
      height: 32,
      // m: 0,
      marginRight: 4,
      p: 1,
      color: "inherit",
      bg: "transparent",
      border: 0,
      ":focus": {
        outline: "none",
        // outline: "2px solid",
      },
      ":hover": {
        // color: "primary",
        outline: "2px solid",
      },
    },
    menuButton: {
      // appearance: "none",
      cursor: "pointer",
      // // width: 32,
      // // height: 32,
      ml: 4,
      py: 1,
      // // m: 0,
      // // marginRight: 4,
      // // p: 1,
      color: "text",
      // bg: "transparent",
      // // border: 0,
      ":focus": {
        outline: "none",
        // outline: "2px solid",
      },
      ":hover": {
        // color: "primary",
        outline: "2px solid",
      },
    },
    themeButton: {
      appearance: "none",
      display: "inline-block",
      // width: "127px",
      // maxWidth: "72px",
      // width: "100%",
      textAlign: "center",
      lineHeight: "inherit",
      textDecoration: "none",
      // fontSize: "inherit",
      fontSize: 1,
      fontWeight: "bold",
      // m: 0,
      ml: 4,
      px: 2,
      py: 1,
      border: 0,
      borderRadius: 4,
    },
    themeIconButton: {
      verticalAlign: "bottom",
      cursor: "pointer",
      // ":hover": {
      //   color: "secondary",
      //   // color: "#3CDCFF",
      // },
    },
    mainMap: {
      width: "100%",
      // height: "100%",
      height: [
        "calc(100vh - 118px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
      ],
      flex: "1 1 auto",
    },
    main: {
      width: "100%",
      // height: "100%",
      height: [
        "calc(100vh - 118px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
      ],
      flex: "1 1 auto",
      display: "flex",
      overflowY: "overlay",
    },
    content: {
      width: "90%",
      maxWidth: 1200,
      mx: "auto",
      // px: 3,
    },
    contentDashboard: {
      width: "90%",
      maxWidth: 1200,
      mx: "auto",
      height: [
        "50vh",
        // "calc(100vh - 330px)",
        // "calc(100vh - 411px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
      ],
      // px: 3,
      // position: ["relative", "absolute", "absolute", "absolute"],
    },
    contentNarrow: {
      maxWidth: 900,
      mx: "auto",
      // px: 3,
    },
    sidebox: {
      display: ["none", "initial", "initial", "initial"],
      // position: ["relative", "absolute", "absolute", "absolute"],
      position: "absolute",
      overflowY: "auto",
      // maxWidth: 400,
      // width: 400,
      maxWidth: [200, 300, 400, 500],
      // height: "80%",
      // height: "96%",
      top: "10%",
      left: "5%",
      px: 4,
      py: 3,
      // backgroundColor: "background",
      // borderColor: "text",
      // borderWidth: "1px",
      // borderStyle: "solid",
      // opacity: 0.7,
      backgroundImage: "backgroundGradient",
      //   "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
    },
    bottombox: {
      display: ["inherit", "none", "none", "none"],
      // position: "initial",
      bottom: "0%",
      px: 2,
      py: 2,
      overflowY: "auto",
      width: "100%",
    },
    sidepanel: {
      borderRightColor: "text",
      borderRightWidth: "1px",
      borderRightStyle: "solid",
    },
    // fullMapView: {
    //   width: "100%",
    //   height: [
    //     "40vh",
    //     // "calc(100vh - 330px)",
    //     // "calc(100vh - 411px)",
    //     "calc(100vh - 131px)",
    //     "calc(100vh - 131px)",
    //     "calc(100vh - 131px)",
    //   ],
    //   // position: "absolute",
    //   position: ["relative", "absolute", "absolute", "absolute"],
    // },
    fullMapViewActivity: {
      width: "100%",
      height: [
        "50vh",
        // "calc(100vh - 330px)",
        // "calc(100vh - 411px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
      ],
      // position: "absolute",
      position: ["relative", "absolute", "absolute", "absolute"],
    },
    fullMapViewStories: {
      width: "100%",
      height: [
        "40vh",
        // "calc(100vh - 330px)",
        // "calc(100vh - 411px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
        "calc(100vh - 131px)",
      ],
      // position: "absolute",
      position: ["relative", "absolute", "absolute", "absolute"],
    },
    selectLayer: {
      width: "100%",
      border: "none",
      cursor: "pointer",
      padding: 0,
      fontSize: responsiveFonts.body,
      fontWeight: "slim",
      // fontSize: 4,
      // fontWeight: "bold",
      // bg: "background",
      "&:focus": {
        outline: "none",
      },
    },
    layerSelectorOption: {
      p: 4,
      // fontSize: 3,
      color: "text",
      bg: "background",
      fontSize: responsiveFonts.bodyVerySmall,
      fontWeight: "slim",
    },

    selectLayerSmall: {
      width: "100%",
      border: "none",
      cursor: "pointer",
      padding: 0,
      // fontSize: responsiveFonts.bodyVerySmall,
      // fontWeight: "slim",
      // fontSize: 4,
      // fontWeight: "bold",
      // bg: "background",
      "&:focus": {
        outline: "none",
      },
    },
    layerSelectorOptionSmall: {
      p: 4,
      // fontSize: 3,
      color: "text",
      bg: "background",
      // fontSize: responsiveFonts.bodyVerySmall,
      fontWeight: "slim",
    },
    homeTitle: {
      fontFamily: "heading",
      fontSize: responsiveFonts.title,
      // fontWeight: "slim",
      pt: [3, 4, 4, 5],
    },
    homeText: {
      fontSize: responsiveFonts.body,
      fontWeight: "slim",
      pt: [2, 4, 4, 4],
      margin: "0px",
    },
    homeLinks: {
      listStyle: "none",
      display: "flex",
      flexDirection: "row",
      // flexDirection: ["column", "row", "row", "row"],
      // display: "grid",
      // gridGap: 3,
      // gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      p: 0,
      mx: 0,
      mt: [4, 4, 6, 5],
    },
    pageTitle: {
      fontFamily: "heading",
      fontSize: responsiveFonts.title,
      // fontWeight: "slim",
      pt: [4, 5, 5, 5],
    },
    pageTitleSmall: {
      fontFamily: "heading",
      fontSize: responsiveFonts.titleSmall,
      // fontWeight: "slim",
      // pt: [3, 4, 4, 4],
      // pt: [4, 5, 5, 5],
      whiteSpace: "nowrap",
    },
    pageText: {
      fontSize: responsiveFonts.body,
      fontWeight: "slim",
    },
    pageTextSmall: {
      fontSize: responsiveFonts.bodySmall,
      fontWeight: "slim",
    },
    splashMessage: {
      position: "absolute",
      top: "20%",
      // top: ["15%", "15%", "15%", "20%"],
      zIndex: 1000,
      fontSize: responsiveFonts.title,
      // fontWeight: "heading",
      fontFamily: "fontBold",
      flex: "0 0 50%",
      textAlign: "center",
      // backgroundColor: [
      //   "backgroundSemiTransparent",
      //   "transparent",
      //   "transparent",
      //   "transparent",
      // ],
    },
    footer: {
      // color: "text",
      backgroundColor: "background",
      // display: "flex",
      // flexDirection: ["column", "row", "row", "row"],
      // flexWrap: "wrap",
      // // alignItems: "center",
      // // justifyContent: "center",
      // alignItems: ["center", "initial", "initial", "initial"],
      // justifyContent: [
      //   "center",
      //   "space-between",
      //   "space-between",
      //   "space-between",
      // ],
      // p: 3,
      borderTop: "4px solid",
      borderTopColor: "primary",
      // fontWeight: "slim",
    },
    footerContent: {
      color: "text",
      width: "90%",
      maxWidth: 1200,
      // backgroundColor: "background",
      display: "flex",
      flexDirection: ["column", "row", "row", "row"],
      flexWrap: "wrap",
      // alignItems: "center",
      // justifyContent: "center",
      alignItems: ["center", "initial", "initial", "initial"],
      justifyContent: [
        "center",
        "space-between",
        "space-between",
        "space-between",
      ],
      // p: 3,
      py: 3,
      // borderTop: "4px solid",
      // borderTopColor: "primary",
      fontWeight: "slim",
      mx: "auto",
    },
    footerSplash: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      ml: "auto",
      mr: "auto",
      color: "text",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      p: 3,
      pb: [4, 3, 3, 3],
      zIndex: 900,
      fontWeight: "slim",
      // borderTop: "5px solid",
      // borderTopColor: "primary",
    },
    footerLink: {
      color: "text",
      textDecoration: "none",
      // fontWeight: "bold",
      whiteSpace: "nowrap",
      px: 2,
      "&:hover": {
        color: "secondary",
      },
    },
    footerThemeButton: {
      position: "absolute",
      left: "5%",
      // zIndex: 300,
    },
    footerThemeButtonMobile: {
      position: "relative",
      // left: "5%",
      // zIndex: 300,
    },
    loginContent: {
      maxWidth: 300,
      mx: "auto",
      py: 5,
    },

    // Custom features
    centered: {
      mx: "auto",
    },
    noDecoration: {
      textDecoration: "none",
    },

    secondaryIndicatorTitle: {
      m: 0,
      whiteSpace: "nowrap",
      fontWeight: "heading",
      fontSize: responsiveFonts.secondaryIndicatorTitle,
    },

    secondaryIndicatorValue: {
      m: 0,
      whiteSpace: "nowrap",
      fontSize: responsiveFonts.secondaryIndicatorValue,
    },

    secondaryIndicatorVariation: {
      m: 0,
      whiteSpace: "nowrap",
      fontSize: responsiveFonts.secondaryIndicatorVariation,
    },

    indicatorUpIcon: {
      // color: "#A6D61D",
      ml: 3,
      verticalAlign: "bottom",
    },
    indicatorDownIcon: {
      color: "secondary",
      ml: 3,
      verticalAlign: "bottom",
    },
    indicatorUnknown: {
      // color: "#eeeeee",
      ml: 3,
      verticalAlign: "bottom",
    },
    dashboardWidgetTitle: {
      mt: [4, 2, 2, 4],
      mb: [3, 2, 2, 2],
    },
  },
}

// export default {
//   ...future,
//   initialColorMode: "light",

//   colors: {
//     text: "black",
//     background: "white",
//     primary: "#0048FF",
//     secondary: "#ff607b",
//     accent: "#ff8bcb",
//     muted: "#d3e9ef",
//     modes: {
//       dark: {
//         text: "#fff",
//         background: "#000",
//         primary: "#0cf",
//         secondary: "#09c",
//         muted: "#111",
//       },
//     },
//   },
//   fonts: {
//     body: "system-ui, sans-serif",
//     heading: "system-ui, sans-serif",
//     monospace: "Menlo, monospace",
//   },
//   fontWeights: {
//     body: 400,
//     heading: 900,
//     bold: 700,
//   },
//   lineHeights: {
//     body: 1.5,
//     heading: 1.125,
//   },
//   buttons: {
//     primary: {
//       color: "white",
//       bg: "primary",
//     },
//     secondary: {
//       color: "white",
//       bg: "secondary",
//     },
//   },
//   layout: {
//     header: {
//       color: "white",
//       backgroundColor: "primary",
//     },
//     navLink: {
//       color: "white",
//       textDecoration: "none",
//     },
//     footer: {
//       color: "white",
//       backgroundColor: "black",
//     },
//   },
// }
