// Styles

import "./src/fonts/fonts.css"
import "mapbox-gl/dist/mapbox-gl.css"
import "./src/styles/global.css"
// import "./src/components/animations/orb-animation.css"
// import "./src/components/animations/splash-animation.css"

// Global context

import React from "react"
import { GlobalProvider } from "./src/context/global-context"

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
)
