// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activity-js": () => import("./../src/pages/activity.js" /* webpackChunkName: "component---src-pages-activity-js" */),
  "component---src-pages-metrics-total-data-js": () => import("./../src/pages/metrics/total-data.js" /* webpackChunkName: "component---src-pages-metrics-total-data-js" */),
  "component---src-pages-stories-smartcities-js": () => import("./../src/pages/stories/smartcities.js" /* webpackChunkName: "component---src-pages-stories-smartcities-js" */)
}

