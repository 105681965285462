module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":false,"database":false,"firestore":false,"storage":false,"messaging":false,"functions":true,"performance":false},"credentials":{"apiKey":"AIzaSyBn9eLTBNdTtt8K6woTsczbDgenFPKYlSo","authDomain":"dataexplorer-97cb0.firebaseapp.com","databaseURL":"https://dataexplorer-97cb0.firebaseio.com","projectId":"dataexplorer-97cb0","storageBucket":"dataexplorer-97cb0.appspot.com","messagingSenderId":"480397793753","appId":"1:480397793753:web:3afd811bf4ee7016dc21c8","measurementId":"G-EJ973JBM6W"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
